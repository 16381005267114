import { useEffect } from "react";
import Scrambler from "scrambling-letters";
import "./App.css";
import btc from "./bitcoin.png";
import eth from "./ethereum.png";
import logo from "./logo.svg";
import mainlogo from "./main-logo.svg";
import nft from "./nft.png";
import swap from "./swap.png";
import usdt from "./tether.png";

function App() {
  useEffect(() => {
    Scrambler({
      target: "[data-scrambler]",
      random: [200, 1000],
      speed: 50,
    });
  }, []);

  return (
    <main className="app">
      <div className="logo-wrapper">
        <img src={mainlogo} className="logo" alt="SofiDex Logo" />
        <img src={logo} className="anim-logo" alt="Logotype" />
      </div>
      <div className="assets">
        <img src={btc} alt="BTC Logo" />
        <img src={eth} alt="ETH Logo" />
        <img src={nft} alt="NFT Logo" />
        <img src={swap} alt="SWAP Logo" />
        <img src={usdt} alt="USDT Logo" />
      </div>
      <p data-fader>
        Here at SofiDex, we are on a mission to make crypto-backed loans a quick
        and straightforward process while providing the following:
      </p>
      <ul id="scramble" data-fader>
        <li data-scrambler>Lowest 3% APR rates on the market ✅</li>
        <li data-scrambler>Up to 90% LTV with no hidden fees ✅</li>
        <li data-scrambler>Quick and simple process ✅</li>
        <li data-scrambler>Coming Soon - watch this space 🚀</li>
      </ul>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </main>
  );
}

export default App;
